<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        齊心鞋力 益起行走
      </div>
      <p>2023年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="齊心鞋力 益起健走">
        <p class="image_desc">齊心鞋力 益起健走</p>
        <p class="image_desc">「齊心鞋力益起行走」活動主視覺</p>
        <p class="image_desc">(圖片提供：筆記網路)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          「齊心鞋力，益起行走」以「用愛守護踏在臺灣寶島的每一雙腳」為口號，倡議民眾透過健走累積運動相關數據，並將健康數據視為數據治理之重要應用領域，引領公益團體號召民眾一同參與「數據公益」，活動過程中蒐集100筆數據即捐贈1雙跑鞋給偏鄉民眾，促進全民參與行為公益共好。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          筆記網路訂於112年1月1日至2月25日期間，邀請全臺22縣市共襄盛舉，創造萬人響應的線上公益健走競賽。
        </div>
        <div class="content-desc">並於112年1月1日上午結合「希望基金會」於花博公園圓山廣場舉辦 「2023元旦健走」，藉由現場活動氣氛宣傳活動開跑之餘，鼓勵號召民眾踴躍參與。</div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          透過健行筆記APP取得民眾捐贈之GPX步行軌跡或運動過程產生之相關數據，與地理資訊科學研究專題中心討論研究方向，進行全台健走地區/步道熱點分佈分析，並透過逐年擴散線上競賽活動，鼓勵民眾參與運動公益的同時捐贈出更多元數據，如：族群特徵、步道、運動資訊等，建立運動公益數據生態系，作為步道加強管理及環境改善依據與形成其他研究方向之發想立基。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益</div>
        <div class="content-desc">
          此次健走活動結合健康數據呈現，也將「主動利他、共創共好」的概念帶入，促進臺灣運動與健康照護研究應用與服務發展。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11108',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/111-08.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
